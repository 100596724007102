<template>
  <YLayoutBodyMenu>
  <el-card style="min-height: 45rem">
    <div style="margin-bottom: 1rem">
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input v-model="searchForm.keyword" placeholder="用户昵称|手机号｜用户编号"></el-input>
        </el-col>
        <el-col :span="5">
          <el-input v-model="searchForm.keyword_2" placeholder="直接推荐人用户昵称|手机号｜用户编号"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="searchForm.merchant_title" placeholder="关联商家名称"></el-input>
        </el-col>
        <el-col :span="3">
          <el-button-group>
            <el-button @click="searchGo">搜索</el-button>
            <el-button @click="searchReset">重置</el-button>
          </el-button-group>
        </el-col>
      </el-row>
    </div>
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="uid" label="用户编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="u_nickname" label="用户昵称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="u_phone" label="手机号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="from_uid" label="直推人用户编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="from_u_nickname" label="直推人昵称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="from_u_phone" label="直推人手机号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="merchant_title" label="关联商家" show-overflow-tooltip></el-table-column>
      <el-table-column prop="cashier_num" label="核销次数" show-overflow-tooltip></el-table-column>
      <el-table-column prop="time_start" label="首次成为会员时间" show-overflow-tooltip>
        <template v-slot="s">{{ s.row.time_start | date }}</template>
      </el-table-column>
      <el-table-column prop="time_start" label="当前会员到期时间" show-overflow-tooltip>
        <template v-slot="s">{{ s.row.time_end | date }}</template>
      </el-table-column>
      <el-table-column prop="use_order_id" :formatter="e=>e.use_order_id ? e.use_order_id : ''" label="当前会员期次编号" show-overflow-tooltip></el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
  </YLayoutBodyMenu>
</template>

<script>


import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
export default {
  name: "manage",
  components: {YLayoutBodyMenu},
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      searchForm: {
        keyword: "",
        keyword_2: "",
        merchant_title: "",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    searchReset() {
      this.searchForm = {
        keyword: "",
        keyword_2: "",
        merchant_title: "",
      }
      this.searchGo();
    },
    searchGo() {
      this.page = 1;
      this.list = [];
      this.total = 0;
      this.load();
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$u.api.card.memberSearch({
        page: this.page,
        ...this.searchForm,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>